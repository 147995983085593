.topbar{
  width: 100%;
  height: 70px;
  background-color: white;
  color: black;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;
}

@media screen and (max-width: 900px) {
  #address {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  #nav-left {
    display: none;
  }
  #email {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  #linkedin {
    display: none;
  }
}

@media screen and (max-width: 475px) {
  #title-text{
    font-size: 20pt;
  }
}

@media screen and (max-width: 300px) {
  #title-text{
    font-size: 16pt;
  }
}

.wrapper{
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topbar .left{
  display: flex;
  align-items: center;
}

.logo{
  font-size: 40px;
  font-weight: 700;
  text-decoration: none;
  color: inherit;
}

.itemContainer{
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.itemContainer span{
  font-size: 15px; 
  font-weight: 500;
}

.icon{
  font-size: 18px;
  margin-right: 5px;
}

.hamburger{
  width: 32px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.hamburger span{
  width: 100%;
  height: 3px;
  background-color: black;
  transform-origin: left;
  transition: all 1s ease;
}

.contact{
  background-color: white;
  width: 100vw;
  height: calc(100vh - 70px);
  scroll-snap-align: start;
  display: grid;
  grid-template-columns: 1fr, 1fr;
}

.contact-left{
  grid-column: 1 / span1;
  align-self: center;
  justify-self: center;
}

.contact-right{
  align-self: center;
  justify-self: center;
  grid-column: 2 / span1;
  display: grid;
}

#contact-text{
  align-self: center;
  justify-self: center;
}

#contact-image{
  border-radius: 25px;
}

#resume-link{
  text-decoration: none;
}

@media screen and (max-width: 1300px) {
  /* #contact-image{
    height: 25vh;
    border-radius: 25px;
    grid-row: 1 / span1;
  }
  .contact{
    grid-template-rows: 1fr, 1fr;
    grid-template-columns: 1fr;
  }
  .contact-right{
    align-self: center;
    justify-self: center;
    grid-column: 1 / span1;
    grid-row: 2 / span1;
  } */
  .contact-left{
    padding-top: 10px;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
  }
  .contact-right{
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@media screen and (max-width: 800px) {
  #contact-image{
    height: 25vh;
    border-radius: 25px;
    grid-row: 1 / span1;
  }
  .contact{
    grid-template-rows: 1fr, 1fr;
    grid-template-columns: 1fr;
  }
  .contact-right{
    align-self: center;
    justify-self: center;
    grid-column: 1 / span1;
    grid-row: 2 / span1;
  }
  /* .contact-left{
    padding-top: 10px;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
  } */
  .contact-right{
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@media screen and (max-width: 400px), (max-height: 800px){
  .contact-right{
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
  }
}

.contact-right h2{
  font-size: 30px;
}

.contact-right form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.contact-right input{
  width: 300px;
  height: 30px;
  font-size: 14px;
}

.contact-right textarea{
  width: 300px;
  height: 200px;
  font-size: 14px;
}

.contact-right button{
  width: 150px;
  height: 30px;
  color: white;
  background-color: crimson;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
}

.contact-right button:focus{
  outline: none;
}

.aboutme{
  background-color: white;
  width: 100vw;
  height: calc(100vh - 70px);
  scroll-snap-align: start;
  display: grid;
  grid-template-columns: 1fr, 1fr;
}

.About-Me-Left{
  justify-self: center;
  align-self: center;
  grid-column: 1 / span1;
  grid-row: 1 / span1;
}

.About-Me-Left img{
  justify-content: center;
}

.About-Me-Right{
  justify-self: center;
  align-self: center;
  grid-column: 2 / span1;
  grid-row: 1 / span1;
}

@media screen and (max-width: 1675px) {
  .aboutme{
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr, 1fr !important;
  }
  .About-Me-Left{
    justify-self: center;
    align-self: center;
    grid-column: 1 / span1;
    grid-row: 1 / span1;
  }
  .About-Me-Left img{
    width: 90%;
    text-align: center;
  }
  .About-Me-Right{
    justify-self: center;
    align-self: center;
    grid-column: 1 / span1;
    grid-row: 2 / span1;
  }
}

@media screen and (max-width: 1200px), (max-height: 1000px){
  .About-Me-Right{
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
  }
  .About-Me-Left{
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@media screen and (max-height: 650px){
  .About-Me-Left{
    padding-top: 15px;
  }
}

@media screen and (max-width: 550px), (max-height: 700px){
  .About-Me-Right{
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
  }
}

@media screen and (max-width: 400px), (max-height: 525px){
  .About-Me-Right{
    -webkit-transform: scale(0.60);
    -moz-transform: scale(0.60);
    -ms-transform: scale(0.60);
    transform: scale(0.60);
  }
}

@media screen and (max-width: 300px), (max-height: 525px){
  .About-Me-Right{
    -webkit-transform: scale(0.50);
    -moz-transform: scale(0.50);
    -ms-transform: scale(0.50);
    transform: scale(0.50);
  }
}

.projects{
  background-color: white;
  width: 100vw;
  height: calc(100vh - 70px);
  scroll-snap-align: start;
  display: grid;
}

.project-scale{
  align-self: start;
  display: grid;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100vw;
}

.project-container{
  width: 90vw;
  /* height: 25vh; */
  height: calc(100vh - 150px);
  display: flex;
  /* align-self: start; */
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;
  /* grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr; */
}

.project-item {
  width: 225px;
  height: 150px;
  border-radius: 20px;
  border: 1px solid lightgrey;
  margin: 10px 20px;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  transition: all .5s ease;
}

.project-card{
  margin: 0 10px;
  width: 270px;
}

/* .card{
  width: 150px;
} */

.project-title{
  align-self: center;
  justify-self: center;
}

@media screen and (max-width: 300px) {
  .project-card{
    margin: 0 0px;
  }
  .project-container{
    width: 100vw;
  }
}

@media screen and (max-width: 1575px), (max-height: 875px) {
  /* .project-container{
    width: 90vw;
    height: 75vh
  } */
}

@media screen and (max-width: 1325px), (max-height: 875px) {
  /* .project-scale{
    align-self: start;
    -webkit-transform: scale(0.50);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    height: 100vh;
  } */
  /* .project-container{
    align-self: start;
    -webkit-transform: scale(0.50);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  } */
  /* .project-card{
    align-self: start;
    -webkit-transform: scale(0.50);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  } */
  /* .btn{
    align-self: start;
    -webkit-transform: scale(0.50);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  } */
  /* .card{
    align-self: start;
    -webkit-transform: scale(0.50);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  } */
  /* .project-item{
    align-self: start;
    -webkit-transform: scale(0.50);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  } */
  /* .card-body{
    align-self: start;
    -webkit-transform: scale(0.50);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  } */
  /* .project-container{
    width:95vw;
  } */
}

.portfolioList .active{
  background-color: lightseagreen;
  color: white;
}

.project-item h3{
  position: absolute;
  text-align: center;
  font-size: 20px;
}

.project-item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 1;
}

.feat_active{
  background-color: lightseagreen;
  color: white;
}

.intro{
  background-color: white;
  width: 100vw;
  height: calc(100vh - 70px);
  scroll-snap-align: start;
  display: flex;
}

.intro .left {
  width: 65%;
}

.left .imgContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 350px){
  #intro-wrapper h1{
    font-size: 40px !important;
  }
  #intro-wrapper h2{
    font-size: 20px !important;
  }
  #intro-wrapper h3{
    font-size: 20px !important;
  }
  #intro-right{
    padding-left: 40px !important;
  }
}

@media screen and (max-width: 850px){
  #intro-image{
    margin-top: 30px !important;
  }
  #intro-wrapper{
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 900px) {
  .intro .right{
    align-content: flex-start;
  }
  .intro .left{
    width: 100% !important;
  }
  #intro{
    flex-direction: row;
  }
  #intro-wrapper{
    padding-left: 45px !important;
  }
  #intro-wrapper h1{
    font-size: 50px;
  }
  #intro-wrapper h2{
    font-size: 25px;
  }
  #intro-wrapper h3{
    font-size: 25px;
  }
}

@media screen and (max-width: 1500px) {
  #intro{
    align-items: center;
    flex-direction: column;
  }
  #intro-image{
    margin-bottom: 65px;
  }
  #intro-wrappper{
    position: fixed !important;
  }
  #intro-right{
    width: 375px !important;
  }
}

.right .intro-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro .right .intro-wrapper h1 {
  font-size: 60px;
  margin: 10px 0;
}

.right .intro-wrapper h2 {
  font-size: 35px;
}

.right .intro-wrapper h3 {
  font-size: 30px;
}

.right a{
  position: absolute;
  bottom: 10px;
  left: 55%;
  transition: 1s ease;
}

.right a:hover{ 
  -webkit-transform: scale(1.75);
  -ms-transform: scale(1.75);
  transform: scale(1.75);
  transition: 0.5s ease;
}

.intro .right::-webkit-scrollbar {
  display: none;
}

.right img{
  width: 30px;
}

.ityped-cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

.app{
  height: 100vh;
}

.sections::-webkit-scrollbar {
  display: none;
}

.topbar::-webkit-scrollbar{
  display: none;
}

.hamburger::-webkit-scrollbar{
  display: none;
}

.sections {
  width: 100%;
  height: calc(100vh - 70px);
  background-color: white;
  position: relative;
  top: 65px;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
}

.active{
  background-color: lightseagreen;
  color: white;
}

.active .hamburger :first-child{
  background-color: white;
  transform: rotate(45deg);
}

.active .hamburger :last-child{
  background-color: white;
  transform: rotate(-45deg);
}

.active .hamburger :nth-child(2){
  opacity: 0;
}

.menu{
  width: 300px;
  height: 100vh;
  background-color: lightseagreen;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
}

@media screen and (max-width: 900px) {
  .menu {
    width: 150px;
  }
  #menu-list {
    font-size: 15px;
  }
}

.active1{
  right: 0;
}

.menu ul{
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 30px;
  font-weight: 300;
  color: white;
  width: 60%;
}

.menu li{
  margin-bottom: 20px;
}

.menu li:hover{
  font-weight: 500;
}

.menu ul li a{
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}